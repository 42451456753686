<template>
  <div class="container-fluid feeds">
    <div class="col-md-2 hidden-xs hidden-sm"></div>
    <div class="col-md-8 col-sm-12">
        <div class="my-bets">              
          <div class="my-all-bets">
            <div>
              <p></p>
              <ul class="list-inline">
                  <li><i class="fa fa-check-circle-o won" aria-hidden="true"></i> {{ $t('betslip.win') }}</li>
                  <li><i class="fa fa-circle running" aria-hidden="true"></i> {{ $t('betslip.running') }}</li>
                  <li><i class="fa fa-times-circle-o lost" aria-hidden="true"></i> {{ $t('betslip.lost') }}</li>
                  <li><i class="fa fa-check-circle-o" aria-hidden="true"></i> {{ $t('betslip.finnished') }}</li>
                  <li><i class="fa fa-times-circle" aria-hidden="true"></i> {{ $t('betslip.cancel') }} </li>
                  <li><i class="fa fa-minus-circle" aria-hidden="true"></i> {{ $t('betslip.void') }} </li>
                  <li><i class="fa fa-times-circle-o" aria-hidden="true"></i> {{ $t('betslip.rolled') }} </li>
              </ul>
            </div>
            <template v-if="slips">
              <div class="">
                <span class="" @click="$router.go(-1)"><i class="icofont-arrow-left" style="cursor:pointer;"></i>{{'\xa0'}} {{'\xa0'}} <b>{{ $t('betslip.bet-id') }}: {{ slips.bet_code }}</b></span>
              </div>
              <div class="bet-id-status">
                <p> {{ $t('betslip.prematch') }} {{ slips.date_placed | moment('D/MM - HH:mm') }}  </p>

                <span class="won" v-if="slips.won && slips.status && slips.cancelled == null">{{ $t('betslip.win') }}</span>
                <span class="lost" v-if="!slips.won && slips.status && slips.cancelled == null">{{ $t('betslip.lost') }}</span>
                <span class="text-orange" v-if="!slips.won && !slips.status && slips.cancelled == null">{{ $t('betslip.running') }}</span>
              </div>
              <template v-if="slips.type != 'Jackpot' && time.length == 0">
                <div class="bet-id-status" >
                  <button class="btn btn-sm bg-blue" @click="shareBetslip(slips.bet_id,slips.bet_code)" v-if="!slips.won && !slips.status && slips.cancelled == null">{{ $t("betslip.share") }}</button>
                  <button class="btn btn-sm bg-blue" @click="cashout()" v-if="!slips.won && !slips.status && slips.cancelled == null">{{ $t("betslip.cashout") }}</button>
                </div>
              </template>
              <div class="payout">
                <div class="payout-info">
                  <div class="ff">
                    <p>{{ $t('betslip.stake') }}</p>
                    <p>{{ slips.stake.toLocaleString() }}</p>
                  </div>
                  <div class="space"></div>
                  <div>
                    <p>{{ $t('betslip.winning') }}</p>
                    <p>{{ slips.possible_win.toFixed(0) }}</p>
                  </div>
                </div>
                <div class="text-right">
                  <p>{{ $t('betslip.total') }}</p>
                  <p>{{ won }}/{{ lost }}/{{ slips.matches.length }}</p>
                </div>
              </div>
              <div class="">
                <p>{{ $t('betslip-select.total-odds') }}({{ slips.odds }})</p>
              </div>
              <template v-if="!load">
                <div class="my-bet-match" v-for="slip in matches" :key="slip.id">
                  <div class="flexbox">
                    <p>
                      <span v-if="slip.won && !slip.cancelled"><i class="icofont-check-circled won" aria-hidden="true"></i></span>
                        <span v-if="!slip.won && slip.status && slip.resulted && !slip.cancelled"><i class="icofont-close-circled lost"  aria-hidden="true"></i></span>
                        <span v-if="!slip.won && !slip.status && !slip.resulted && !slip.cancelled"><i class="icofont-disc running" aria-hidden="true"></i></span>
                        <span v-if="slip.cancelled && !slip.voided"><i class="fa fa-times-circle" aria-hidden="true"></i></span>
                        <span v-if="!slip.voided && slip.cancelled"><i class="fa fa-minus-square" aria-hidden="true"></i></span>
                        <span v-if="slip.rolled"><i class="fa fa-times-circle-o" aria-hidden="true"></i></span>
                        {{ splitTeam(slip.name)[0] }}
                    </p>
                    <span class="scores" v-if="slip.scores.ft">{{ slip.scores.ft }}</span>
                    <span class="scores" v-if="!slip.scores.ft"> vs </span>
                    <p class="text-right">{{ splitTeam(slip.name)[1] }}</p>
                  </div>
                  <div class="flexbox">
                    <div class="flexbox flex-grow">
                      <p>{{ $t('betslip.type') }}</p>
                      <!-- {{ (slip.specifier != 'n/a') ? slip.specifier : '' }} -->
                      <p class="text-right">{{ marketTranslate(slip.market) }} </p>
                    </div>
                    <div class="flexbox flex-grow">
                      <p>{{ $t('betslip.ft-results')}}</p>
                      <p class="text-right"> {{ slip.scores.ft }}</p>
                    </div>
                  </div>
                  <div class="flexbox">
                    <div class="flexbox flex-grow">
                      <p>{{ $t('betslip.pick') }}</p>
                      <p class="text-right">{{  marketSpecifier(slip.pick) }} ({{ slip.odd }})</p>
                    </div>
                    <div class="flexbox flex-grow">
                      <p>{{ $t('betslip.outcome')}}</p>
                      <p class="text-right">{{  marketSpecifier(slip.pick) }}</p>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <Loader/>
              </template>

              <modal @close="toggleModal" v-if="show">
                <div class="socials">
                  <ShareNetwork 
                    network="facebook"
                    :url="shareUrl"
                    title="Rahisibet"
                  >
                    <span class=""><img src="/images/facebook.png" @click="shareLink('facebook')" alt=""></span>
                  </ShareNetwork>
                  <ShareNetwork 
                    network="twitter"
                    :url="shareUrl"
                    title="Rahisibet"
                  >
                  <span class=""><img src="/images/twitter.png" alt=""></span>
                  </ShareNetwork>
                  <ShareNetwork 
                    network="whatsapp"
                    :url="shareUrl"
                    title="Rahisibet"
                  >
                  <span class=""><img src="/images/whatsapp.png" alt=""></span>
                  </ShareNetwork>
                </div>
                <div class="">
                  <input
                    type="text"
                    class="form-control"
                    v-model="shareCode"
                    ref="shareUrl"
                  />
                  <br>
                  <button class="btn bg-orange btn-block" v-clipboard:copy="shareCode">{{ $t('betslip.copy-link') }}</button>
                </div>
              </modal>
            </template>
            
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import Loader from "@/components/sections/loader.vue";
import {mapGetters} from "vuex";
import modal from "./../partials/modal.vue";
import BetslipService from "@/services/betslip";

export default {
  name: 'single-bet',
    props: {
      msg: String
    },
    components: {
      modal,
      Loader
    },
    data() {
      return {
        show:false,
        baseUrl: window.location.origin,
        load: false,
        shareUrl:'',
        shareCode:''
      }
    },
    methods: {
      splitTeam: function(str) {
        if(str.split('vs.').length > 1) {
          return str.split('vs.')
        } else if(str.split('vs').length > 1) {
          return str.split('vs')
        } else {
          return str.split('v')
        }
      },
      marketSpecifier: function (name) {
        if( this.$i18n.te("markets." + name.toLowerCase()) )
        {
          return  this.$i18n.t("markets." + name.toLowerCase()).toLowerCase().replace(/\w/, firstLetter => firstLetter.toUpperCase())
        }
        else
        {
          return name
        }
      },
      marketTranslate: function(mak) {
        let regex = /[\s)(&/]+/g;
        let odn = mak.trim().toLowerCase();
        odn = odn.replace(regex,"-");
        odn = odn.toLowerCase();
        return this.$i18n.te("markets." + odn) ? this.$i18n.t("markets." + odn).toLowerCase().replace(/\w/, firstLetter => firstLetter.toUpperCase()) : mak.toLowerCase().replace(/\w/, firstLetter => firstLetter.toUpperCase())
      },
      toggleModal: function() {
        this.show = !this.show;
      },
      showShareBetslip: function (){
        this.shareUrl = `${this.baseUrl}/shared-slip/${this.slips.bet_code}`;
        this.show = true;
      },
      shareBetslip: function (betid,betcode) {
        let payload = {
          bet: betid
        }

        this.shareCode = betcode;

        BetslipService.getActiveSharedBet(payload).then(() => {
         
          this.shareUrl = `${this.baseUrl}/shared-slip/${betcode}`;
          this.show = true;
        });
      },
      
      cashout: function() {
        this.$swal(
          this.$i18n.t('betslip.cashout-msg'),
          "",
          "error"
        );
      }
    },
    computed:{
      ...mapGetters({
        slips: 'bet',
        matches: 'matches'
      }),
      won() {
        let slip = this.slips ? this.matches.filter( x => x.status == 1 && x.won == 1 && x.voided == 0) : [];
        return slip.length;
      },
      lost() {
        let slip = this.slips ? this.matches.filter( x => x.status == 1 && x.won == 0 && x.voided == 0) : [];
        return slip.length;
      },
      time() {
        return this.slips ? this.matches.filter( x => {
          var d1=new Date(x.scheduled);
          var currentdate=new Date();
           return d1.getTime() < currentdate.getTime()
        }) : [];
      }
    },
    async mounted() { 
      this.load = true;
      await this.$store.dispatch("get_single_bet", {
        bet_id: this.$route.params.id
      });
      this.load = false;
    }
}
</script>
<style scoped>
  .my-all-bets .my-bet-match p {
    margin-bottom: 4px;
  }
  p.icons i {
    margin-right: 0px;
  }
  @media(max-width:767px) {
    .my-bet-match {
      font-size: 12px;
    }
  }
  @media(min-width:768px) { 
    .my-all-bets .my-bet-match p[data-v-7b2f256c] {
      margin-bottom: 4px;
      flex-basis: 30%;
    }
  }
  .bet-id-status {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
  .socials {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  .socials img {
    height: 30px;
    width: 30px;
    cursor: pointer;
  }
  .payout-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .payout p {
    margin-bottom: 0px !important;
  }
  .space {
    background-color: var(--a-grey);
    width: 1px;
    height: 30px;
    margin: auto 10px;
  }
</style>